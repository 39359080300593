import cards from "./home_cards.png";
import expo from "./expo.png";
import expo02 from "./expo02.png";
import feature from "./feature.png";
import hero from "./home_hero.png";
import hero2 from "./home_hero2.png";
import tv from "./tv_icon.png";
import logo from "./logo.svg";
import mockup from "./mockup.png";
import mockup2 from "./mockup2.png";
import playBadge from "./google-play-badge.svg";
import csc from "./csc_icon.png";
import scene from "./scene.png";

const components = {
  hero,
  hero2,
  cards,
  csc,
  feature,
  expo,
  expo02,
  logo,
  playBadge,
  scene,
  mockup,
  mockup2,
  tv,
};

export default components;
